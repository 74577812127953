import React from "react";
import { Script } from "gatsby";
import AppProvider from "~context/AppContext/index.jsx";
import NewsletterProvider from "~context/NewsletterContext/index.jsx";

const Provider = ({ element }) => (
  <>
    <AppProvider>
      <NewsletterProvider>{element}</NewsletterProvider>
    </AppProvider>
  </>
);

export default Provider;
