exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-collections-jsx": () => import("./../../../src/pages/collections.jsx" /* webpackChunkName: "component---src-pages-collections-jsx" */),
  "component---src-pages-products-jsx": () => import("./../../../src/pages/products.jsx" /* webpackChunkName: "component---src-pages-products-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-templates-insight-idea-starter-jsx": () => import("./../../../src/templates/insight-idea-starter.jsx" /* webpackChunkName: "component---src-templates-insight-idea-starter-jsx" */),
  "component---src-templates-insight-jsx": () => import("./../../../src/templates/insight.jsx" /* webpackChunkName: "component---src-templates-insight-jsx" */),
  "component---src-templates-insight-series-jsx": () => import("./../../../src/templates/insight-series.jsx" /* webpackChunkName: "component---src-templates-insight-series-jsx" */),
  "component---src-templates-insights-jsx": () => import("./../../../src/templates/insights.jsx" /* webpackChunkName: "component---src-templates-insights-jsx" */),
  "component---src-templates-investor-jsx": () => import("./../../../src/templates/investor.jsx" /* webpackChunkName: "component---src-templates-investor-jsx" */),
  "component---src-templates-material-index-jsx": () => import("./../../../src/templates/material-index.jsx" /* webpackChunkName: "component---src-templates-material-index-jsx" */),
  "component---src-templates-material-jsx": () => import("./../../../src/templates/material.jsx" /* webpackChunkName: "component---src-templates-material-jsx" */),
  "component---src-templates-mlp-jsx": () => import("./../../../src/templates/mlp.jsx" /* webpackChunkName: "component---src-templates-mlp-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-plp-jsx": () => import("./../../../src/templates/plp.jsx" /* webpackChunkName: "component---src-templates-plp-jsx" */),
  "component---src-templates-press-jsx": () => import("./../../../src/templates/press.jsx" /* webpackChunkName: "component---src-templates-press-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */)
}

