/* eslint-disable camelcase */
import React, { createContext, useState, useEffect } from "react";

export const NewsletterContext = createContext({});

/** ============================================================================
 * @component
 * Global state provider for lightboxes and headers.
 * @return {node}  todo: document
 */
const NewsletterProvider = ({ children }) => {
  // --------------------------------------------------------------------------
  // context / ref / state

  const [defaultNewsletterData, setDefaultNewsletterData] = useState(null);
  const [newsletterActive, setNewsletterActive] = useState(false);

  // --------------------------------------------------------------------------
  // lifecycle

  // ...

  // --------------------------------------------------------------------------
  // render

  return (
    <NewsletterContext.Provider
      value={{
        defaultNewsletterData,
        setDefaultNewsletterData,
        newsletterActive,
        setNewsletterActive
      }}
    >
      {children}
    </NewsletterContext.Provider>
  );
};

export default NewsletterProvider;
