/* eslint-disable camelcase */
import React, { createContext, useState, useEffect } from "react";
// eslint-disable-next-line import/no-unresolved
import { globalHistory } from "@reach/router";

export const AppContext = createContext({});

/** ============================================================================
 * @component
 * Global state provider for lightboxes and headers.
 * @return {node}  todo: document
 */
const AppProvider = ({ children }) => {
  // --------------------------------------------------------------------------
  // context / ref / state

  const [modalActive, setModalActive] = useState(false);
  const [navActive, setNavActive] = useState(false);
  const [toastActive, setToastActive] = useState(false);
  const [navPreselect, setNavPreselect] = useState(null);
  const [headerOffset, setHeaderOffset] = useState(null);
  const [insightsMode, setInsightsMode] = useState(false);
  const [insightsTheme, setInsightsTheme] = useState(`dark`);
  const [pathname, setPathname] = useState(null);

  // --------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (typeof window === `undefined`) return;

    window?.sessionStorage.setItem(`firstPage`, window?.location);

    return () => window?.sessionStorage.clear();
  }, []);

  useEffect(() => {
    if (typeof window !== `undefined` && window?.location?.pathname) {
      setPathname(window.location.pathname);
    }

    return globalHistory.listen(({ location }) => {
      setPathname(location.pathname);
    });
  }, []);

  useEffect(() => {
    if (!pathname) {
      return;
    }

    setInsightsMode(pathname.startsWith(`/insights`));
  }, [pathname]);

  useEffect(() => {
    if (!toastActive) return;

    setTimeout(() => {
      setToastActive(false);
    }, 2000);
  }, [toastActive]);

  // --------------------------------------------------------------------------
  // render

  return (
    <AppContext.Provider
      value={{
        insightsMode,
        insightsTheme,
        setInsightsTheme,
        pathname,
        modalActive,
        setModalActive,
        navActive,
        setNavActive,
        toastActive,
        setToastActive,
        navPreselect,
        setNavPreselect,
        headerOffset,
        setHeaderOffset
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
